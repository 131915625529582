import rectangleImg from "../components/images/reactangle.svg";
import tax3Img from "../components/images/tax3_creative.png";
import bubbicImg from "../components/images/bubbic_creative.png";
import nastyImg from "../components/images/nasty_creative.png";
import kusomeganeImg from "../components/images/kusomegane_creative.png";
import wasshoiImg from "../components/images/wasshoi_creative.png"
import ifcImg from "../components/images/ifc_creative.png";
import luchia from "../components/images/luchia_creative.png";
import ThootImg from "../components/images/thoot_creative.png";
import NestImg from "../components/images/nest_creative.png";
import TunageruImg from "../components/images/tunageru_creative.png";
import KeioImg from "../components/images/keio_creative.png";
import MovanageImg from "../components/images/movanage_creative.png";

export const images = [tax3Img, bubbicImg, nastyImg, kusomeganeImg, ifcImg, luchia, wasshoiImg,ThootImg,NestImg,TunageruImg,KeioImg,MovanageImg,rectangleImg, rectangleImg, rectangleImg ,rectangleImg ,rectangleImg,rectangleImg, rectangleImg ,rectangleImg];